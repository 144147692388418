import React from 'react';
import { object, bool } from 'prop-types';
import loadable from '@loadable/component';

import {
  BRONZE_SUBSCRIPTION,
  CONTACT_US,
  SUBSCRIPTION_ROUTE,
  HOME_LOGGED_IN_ROUTE,
} from 'constants/navigation';
import {
  STARS_NAV,
  EMAIL_NAV,
  SUBSCRIPTION_NAV,
  HOME_NAV,
} from 'constants/font-awesome';

import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';

const Page = loadable(() => import('components/page'));
const Loading = loadable(() => import('components/loading'));
const LazySubscriptionBanner = loadable(() => import('components/banners/in-app-banners/subscription'));
const LazyBronzeSubscriptionView = loadable(() => import('views/subscription/bronze'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const SuspendedBronzeSubscriptionPage = ({ loading, subscriptionPrices }) => {
  const styles = useStyles();

  return (
      <Page title="Bronze"
        description="Bronze Subscription (500 active loyalty cards)">
        <LazySubscriptionBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          Bronze Subscription
        </Typography>
        <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
         500 active loyalty cards
        </Typography>
        <>
        {loading ? (
          <Grid container spacing={3}>
            <Loading/>
          </Grid>
        ) : (
          <LazyBronzeSubscriptionView subscriptionPrices={subscriptionPrices}/>
        )}
        </>
        <LazyOverrideFooter
          navItems={ [
            { route: HOME_LOGGED_IN_ROUTE, icon: HOME_NAV, message: 'Home' },
            { route: BRONZE_SUBSCRIPTION, icon: STARS_NAV, message: 'Bronze' },
            { route: SUBSCRIPTION_ROUTE, icon: SUBSCRIPTION_NAV, message: 'Subscription' },
            { route: CONTACT_US, icon: EMAIL_NAV, message: 'Contact' },
          ]}
        currentItem="Bronze"
        />
      </Page>
  );
};

SuspendedBronzeSubscriptionPage.propTypes = {
  loading: bool.isRequired,
  subscriptionPrices: object.isRequired,
};

export default SuspendedBronzeSubscriptionPage;
