import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

import { baseStyles } from 'suspended-page/styles';

export default makeStyles({
  ...baseStyles,
  indexheading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
});
